<template>
  <div class="category-root">
    <div class="newCategory" v-loading="loading">
      <bread-nav></bread-nav>
      <category @syncData="syncFilter" :count="goodsList.count"></category>
      <div class="goods-list">
        <div class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition">
          <div class="el-table__header-wrapper">
            <table cellspacing="0" cellpadding="0" border="0" class="el-table__header">
              <thead>
              <tr>
                <th class="is-center is-leaf" style="width:120px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">商品图片</div></th>
                <th class="is-center is-leaf" style="width:150px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">商品名称</div></th>
                <th class="is-center is-leaf" style="width:150px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">国际条码</div></th>
                <th class="is-center is-leaf" style="width:150px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">商品货号</div></th>
                <th class="is-center is-leaf" style="width:120px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">产品图片</div></th>
                <th class="is-center is-leaf" style="width:120px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">产品颜色</div></th>
                <th class="is-center is-leaf" style="width:150px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">品牌</div></th>
                <th class="is-center is-leaf" style="width:120px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">整箱数量</div></th>
                <th class="is-center is-leaf" style="width:120px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">库存数量</div></th>
                <th class="is-center is-leaf" style="width:120px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">您的价格</div></th>
                <th class="is-center is-leaf" style="width:200px;background: rgb(250, 250, 250); color: rgb(51, 51, 51);"><div class="cell">订购数量</div></th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in skuList" :key="index">
                   <td class="is-center"><img :src="$img(item.goods_image, { size: 'small' })" @click="$router.pushToTab({ path: '/sku-' + item.goods_id })" width="70" /></td>
                   <td colspan="10" style="padding-top:0px;" align="center">
                      <table cellspacing="0" style="border:1px solid #EBEEF5;margin-top:12px" cellpadding="0" v-for="(sku_item, sku_index) in skuList[index].sku_list" :key="sku_index">
                        <tr>
                          <td width="150" style="text-align: center;padding:0px;">{{sku_item.goods_name}}</td>
                          <td width="150" style="text-align: center;padding:0px;">{{sku_item.sku_no}}</td>
                          <td width="150" style="text-align: center;padding:0px;">{{item.goods_no}}</td>
                          <td width="120" style="text-align: center;padding:0px;"><img :src="$img(sku_item.sku_image, { size: 'small' })" @click="$router.pushToTab({ path: '/sku-' + sku_item.goods_id })" style="margin: 8px 0px; width: 60px;" /></td>
                          <td width="120" style="text-align: center;padding:0px;">{{sku_item.color_name}}</td>
                          <td width="150" style="text-align: center;padding:0px;">{{item.brand_name}}</td>
                          <td width="120" style="text-align: center;padding:0px;">{{item.total}}</td>
                          <td width="120" style="text-align: center;padding:0px;">{{ logined ? sku_item.stock : "仅会员可见" }}</td>
                          <td width="120" style="text-align: center;padding:0px;">{{ logined ? sku_item.price : "仅会员可见" }}</td>
                          <td width="200" style="text-align: center;padding:0px;">
                            <div class="cart-flex">
                              <el-input-number size="mini" v-model="cartBuffer[sku_item.subIndex].num" :min="0"></el-input-number>
                              <span class="join-cart" @click="joinCart(sku_item.subIndex)"><img src="~img/alibaba/cart-f.png" /></span>
                            </div>
                          </td>
                        </tr>
                      </table>
                   </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <el-pagination :page-size="pageSize" :current-page.sync="page" @current-change="getGoodsSkuPage()" layout="prev, pager, next, jumper" :total="goodsList.count"> </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {screen1} from "@/api/goods/goodscategory";
import { goodsSkuKtxd } from "@/api/goods/goods"
import GoodsList from "@/layout/components/goods-list"
import BreadNav from "@/layout/components/bread-nav"
import Category from "@/components/Category"
import { mapGetters } from "vuex"
import list from "../../../../../../addon/pc/source/os/src/views/goods/list/list"
export default {
  name: "kantuxiadan",
  components: {
    GoodsList,
    BreadNav,
    Category
  },
  data: () => {
    return {
      goodsCategory: [],
      categoryFixed: false,
      clickIndex: 0,
      loading: true,
      category_more: false,
      brand_more: false,
      origin_more: false,
      category_id: 0,
      brand_id: 0,
      origin: 0,
      catMenu: {},
      goodsList: {},
      orderType: null,
      orderSort: null,
      minPrice: "",
      maxPrice: "",
      pageSize: 30,
      page: 1,
      keyword: "",
      cartBuffer: [],
      colorName: "",
      skuList: []
    }
  },
  created() {
    this.category_id = this.$route.query.category_id || 0
    this.getGoodsSkuPage()
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  computed: {
    ...mapGetters(["member"]),
    logined: function () {
      return this.member !== undefined && this.member !== "" && this.member !== {}
    }
  },
  methods: {
    // 加入购物车
    joinCart(index) {
      //纠正数量
      if( !this.logined )
      {
        //return this.$router.push({path: "/login"})
        return this.$message.error("您尚未登录，请先进行登录");
      }
      let card = this.cartBuffer[index];
      let product = this.skuList[card.pid];
      let goods = product.sku_list[card.cid];
      // if (goods.stock == 0) {
      //   this.$message({
      //     message: "商品已售罄",
      //     type: "warning"
      //   })
      //   return
      // }

      if (card.num == 0) {
        this.$message({
          message: "购买数量不能为0",
          type: "warning"
        })
        return
      }

      // if (card.num > goods.stock) {
      //   this.$message({
      //     message: "购买数量不能大于库存量",
      //     type: "warning"
      //   })
      //   return
      // }

      this.$store
          .dispatch("cart/add_to_cart", {
            sku_id: goods.sku_id,
            num: card.num
          })
          .then((res) => {
            var data = res.data
            if (data > 0) {
              this.$message({
                message: "加入购物车成功",
                type: "success"
              })
            }
          })
          .catch((err) => {
            if (err.message == "您尚未登录，请先进行登录") {
              this.$router.push("/login")
            } else {
              this.$message.error(err.message)
            }
          })

      return true
    },
    // 同步组件数据
    syncFilter(e) {
      this.category_id = e.category_id
      this.brand_id = e.brand_id
      this.origin = e.origin
      this.orderType = e.orderType
      this.orderSort = e.orderSort
      this.minPrice = e.minPrice
      this.maxPrice = e.maxPrice
      this.keyword = e.keyword
      this.loading = true
      this.getGoodsSkuPage()
    },
    getGoodsSkuPage() {
      goodsSkuKtxd(
          {
            brand_id: this.brand_id,
            origin: this.origin,
            category_id: this.category_id,
            order: this.orderType || "",
            sort: this.orderSort || "",
            min_price: this.minPrice,
            max_price: this.maxPrice,
            page_size: this.pageSize,
            page: this.page,
            keyword: this.keyword || ""
          },
          0,
          "GET"
      )
          .then((res) => {
            if (res.code == 0) {
              this.goodsList = res.data
              this.skuList = res.data.list
              //console.log(this.skuList)
              // console.log(this.goodsList.list)
              if (res.data.list.length) {
                let arr = {},subIndex='';
                for( let j=0; j < res.data.list.length; j++ )
                {
                  if( res.data.list[j].sku_list )
                  {
                    for( let i = 0; i < res.data.list[j].sku_list.length; i++ )
                    {
                      subIndex = j+"_"+i;

                      res.data.list[j].sku_list[i].subIndex = subIndex;

                      arr[subIndex] = {num:0,pid:j,cid:i};
                    }
                  }
                  
                }
                this.cartBuffer = arr
              }
            }
            this.loading = false
          })
          .catch((err) => {
            this.$message.error(err.message)
            this.loading = false
          })
    },
    syncGoodsList(e) {
      this.page = e
      this.getGoodsSkuPage()
    }
  },
  destroyed() {
    // 离开该页面需要移除这个监听的事件，不然会报错
    window.removeEventListener("scroll", this.handleScroll)
  }
}
</script>
<style lang="scss" scoped>
.category-root {
  width: $width;
  margin: 0 auto;

  .bread-nav {
    margin-bottom: 30px;
  }
}
.newCategory {
  padding: 30px 0;
  width: $width;
  margin: 0 auto;
  overflow: hidden;

  .goods-list {
    margin-top: 50px;
  }

  .cart-flex {
    display: flex;
    align-items: center;

    .join-cart {
      width: 26px;
      height: 26px;
      background-color: $base-color;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      margin-left: 5px;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .el-pagination {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
