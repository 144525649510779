<template>
    <div>
        <!-- 看图下单头部 -->
        <div class="filter">
            <div class="filter-row">
                <div class="filter-name"><img src="~img/alibaba/category.png" />分类</div>
                <div :class="`filter-list ${category_more ? 'activited' : ''}`">
                    <span :class="`filter-item ${category_id == 0 ? 'activited' : ''}`" @click="setAttr(0, 'category_id')">全部</span>
                    <span :class="`filter-item ${category_id == item.category_id ? 'activited' : ''}`" v-for="(item, index) in catMenu.category" :key="index" @click="setAttr(item.category_id, 'category_id')">{{ item.category_name }}</span>
                </div>
                <span class="more" @click="switchMore('category')">
                    {{ category_more ? "收起" : "更多" }}
                    <img src="~img/alibaba/top.png" v-if="category_more" />
                    <img src="~img/alibaba/bottom.png" v-if="!category_more" />
                </span>
            </div>
            <div class="filter-row">
                <div class="filter-name"><img src="~img/alibaba/brand.png" />品牌</div>
                <div :class="`filter-list ${brand_more ? 'activited' : ''}`">
                    <span :class="`filter-item ${brand_id == 0 ? 'activited' : ''}`" @click="setAttr(0, 'brand_id')">全部</span>
                    <span :class="`filter-item ${brand_id == item.brand_id ? 'activited' : ''}`" v-for="(item, index) in catMenu.brand" :key="index" @click="setAttr(item.brand_id, 'brand_id')"
                        >{{ item.brand_name }}<span class="font_color">({{ item.goods_num }})</span></span
                    >
                </div>
                <span class="more" @click="switchMore('brand')">
                    {{ brand_more ? "收起" : "更多" }}
                    <img src="~img/alibaba/top.png" v-if="brand_more" />
                    <img src="~img/alibaba/bottom.png" v-if="!brand_more" />
                </span>
            </div>
            <div class="filter-row">
                <div class="filter-name"><img src="~img/alibaba/origin.png" />产地</div>
                <div :class="`filter-list ${origin_more ? 'activited' : ''}`">
                    <span :class="`filter-item ${origin == 0 ? 'activited' : ''}`" @click="setAttr(0, 'origin')">全部</span>
                    <span :class="`filter-item ${origin == item ? 'activited' : ''}`" v-for="(item, index) in catMenu.origin" :key="index" @click="setAttr(item, 'origin')">{{ item }}</span>
                </div>
                <span class="more" @click="switchMore('origin')">
                    {{ origin_more ? "收起" : "更多" }}
                    <img src="~img/alibaba/top.png" v-if="origin_more" />
                    <img src="~img/alibaba/bottom.png" v-if="!origin_more" />
                </span>
            </div>
        </div>
        <div class="filter order">
            <div class="order-left">
                排序：
                <span :class="`order-tag ${orderType == 'create_time' ? 'activited' : ''} ${orderSort == 'asc' ? 'asc' : ''}`" @click="setOrderSort('create_time')">最新</span>
                <span :class="`order-tag ${orderType == 'sku_no' ? 'activited' : ''} ${orderSort == 'asc' ? 'asc' : ''}`" @click="setOrderSort('sku_no')">条码</span>
                <span :class="`order-tag ${orderType == 'stock' ? 'activited' : ''} ${orderSort == 'asc' ? 'asc' : ''}`" @click="setOrderSort('stock')">库存</span>
                <span :class="`order-tag ${orderType == 'discount_price' ? 'activited' : ''} ${orderSort == 'asc' ? 'asc' : ''}`" @click="setOrderSort('discount_price')">价格</span>
                <div class="order-price-range">
                    <div class="order-price min">￥<input type="number" min="0" v-model="minPrice" @input="syncData" /></div>
                    -
                    <div class="order-price max">￥<input type="number" min="0" v-model="maxPrice" @input="syncData" /></div>
                </div>
            </div>
            <div class="order-right">
                <div class="search-box">
                    <input type="text" v-model="keyword" placeholder="请输入您要搜索的商品" />
                    <el-button @click="syncData">搜索</el-button>
                </div>
                共<span>{{ count }}</span
                >件商品
            </div>
        </div>
    </div>
</template>

<script>
import { screen1 } from "@/api/goods/goodscategory"

export default {
    name: "goods",
    props: {
        count: {
            type: Number,
            default: () => 0
        }
    },
    data: () => {
        return {
            catMenu: {},
            goodsCategory: [],
            loading: true,
            category_id: 0,
            brand_id: 0,
            category_more: false,
            brand_more: false,
            origin_more: false,
            origin: 0,
            orderType: null,
            orderSort: null,
            minPrice: "",
            maxPrice: "",
            keyword: ""
        }
    },
    created() {
        this.category_id = this.$route.query.category_id || 0
        this.getGoodsCategory()
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)
    },
    methods: {
        getFormData() {
            return {
                category_id: this.category_id,
                brand_id: this.brand_id,
                origin: this.origin,
                orderType: this.orderType,
                orderSort: this.orderSort,
                minPrice: this.minPrice,
                maxPrice: this.maxPrice,
                keyword: this.keyword
            }
        },
        // 分类列表
        getGoodsCategory() {
            screen1(
                {
                    template: 1,
                    level: 1
                },
                0,
                "GET"
            )
                .then((res) => {
                    if (res.code == 0) {
                        this.catMenu = res.data
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.$message.error(err.message)
                    this.loading = false
                })
        },
        switchMore(event) {
            this[`${event}_more`] = !this[`${event}_more`]
        },
        setAttr(value, event) {
            this[event] = value
            this.$emit("syncData", this.getFormData())
        },
        setOrderSort(orderType) {
            this.orderType = orderType
            if (this.orderSort == "desc") {
                this.orderSort = "asc"
            } else if (this.orderSort == "asc") {
                this.orderSort = "desc"
            } else {
                this.orderSort = "desc"
            }
            this.$emit("syncData", this.getFormData())
        },
        syncData() {
            this.$emit("syncData", this.getFormData())
        }
    }
}
</script>

<style lang="scss">
.filter {
    border: 1px solid #d9d9d9;
    border-right: 0;

    .filter-row {
        height: 100%;
        display: flex;
        align-items: flex-start;
        position: relative;
        border-bottom: 1px dashed #d9d9d9;

        &:last-child {
            border: none;
        }

        .filter-name {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $base-color-gray;
            color: $ns-text-color-gray;
            font-size: $ns-font-size-lg;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 1390px;

            img {
                width: 14px;
                height: 14px;
                margin-right: 3px;
            }
        }

        .filter-list {
            width: 1200px;
            padding-right: 80px;
            margin-left: 120px;
            max-height: 50px;
            overflow: hidden;

            &.activited {
                max-height: 1000px;
            }

            .filter-item {
                height: 26px;
                line-height: 26px;
                display: inline-block;
                color: $ns-text-color-black;
                font-size: $ns-font-size-lg;
                padding: 0 4px;
                margin: 12px;
                border-radius: 5px;
                background-color: transparent;
                transition: all 0.3s;
                cursor: pointer;
                user-select: none;
                vertical-align: top;

                &.activited,
                &:hover {
                    background-color: $base-color;
                    color: #ffffff;
                }
            }
        }
    }

    .more {
        position: absolute;
        right: 20px;
        top: 11px;
        font-size: $ns-font-size-lg;
        color: $ns-text-color-gray;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;

        img {
            width: 10px;
            height: 10px;
            margin-left: 3px;
        }
    }

    &.order {
        height: 80px;
        margin-top: 20px;
        border: 1px solid #d9d9d9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 20px;

        .order-left {
            display: flex;
            align-items: center;
            font-size: $ns-font-size-lg;
            color: $ns-text-color-black;

            .order-tag {
                height: 26px;
                line-height: 26px;
                display: inline-block;
                color: $ns-text-color-black;
                font-size: $ns-font-size-lg;
                padding: 0 10px;
                border-radius: 5px;
                margin-right: 4px;
                cursor: pointer;
                user-select: none;

                &:after {
                    display: inline-block;
                    content: "";
                    transform: rotate(45deg);
                    width: 0;
                    height: 0;
                    border: 4px solid;
                    border-color: transparent $base-color-info $base-color-info transparent;
                    vertical-align: 3px;
                    margin-left: 5px;

                    .asc {
                        &:after {
                            border-color: #ffffff transparent transparent #ffffff;
                            vertical-align: -3px;
                        }
                    }
                }

                &.activited,
                &:hover {
                    background-color: $base-color;
                    color: #ffffff;

                    &:after {
                        border-color: transparent #ffffff #ffffff transparent;
                    }

                    &.asc {
                        &:after {
                            border-color: #ffffff transparent transparent #ffffff;
                            vertical-align: -3px;
                        }
                    }
                }
            }

            .order-price-range {
                display: flex;
                align-items: center;

                .order-price {
                    border: 1px solid #d9d9d9;
                    height: 25px;
                    width: 60px;
                    display: flex;
                    align-items: center;
                    color: $ns-text-color-black;
                    font-size: $ns-font-size-base;
                    margin: 0 5px;

                    input {
                        border: none;
                        outline: none;
                        height: 20px;
                        font-size: $ns-font-size-base;
                        color: $ns-text-color-black;
                        width: 40px;
                    }
                }
            }
        }

        .order-right {
            font-size: $ns-font-size-lg;
            color: $ns-text-color-gray;
            display: flex;
            align-items: center;

            > span {
                color: $base-color;
                margin: 0 5px;
            }

            .search-box {
                display: flex;
                align-items: center;
                margin-right: 15px;

                input {
                    border: 1px solid $base-color-gray;
                    width: 300px;
                    height: 40px;
                    box-sizing: border-box;
                    padding: 0 12px;
                    border-top-left-radius: 20px;
                    border-bottom-left-radius: 20px;
                    outline: none;
                }

                .el-button {
                    height: 40px;
                    width: 70px;
                    background-color: $base-color;
                    font-size: $ns-font-size-base;
                    color: #ffffff;
                    text-align: center;
                    border-radius: 0px;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }
            }
        }
    }
}
.font_color {
    color: #666;
}
</style>
